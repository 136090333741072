import moment from "moment";
import { LoanConfig } from "./calculationConfig";
import {
  calculateApprovedAmountWeekly,
  calculateEmiFirstDateWeekly,
  calculateIrrWeekly,
  calculatePreEmiDateWeekly,
  calculateProcesasingFeeWeekly,
  calculateSalaryMultiplierWeekly,
  calculateTenureWeekly,
} from "./weeklycalculations";

export const EmiAmount = (
  rate: number,
  nperiod: number,
  pv: number,
  fv: number,
  type: number
) => {
  if (!fv) fv = 0;
  if (!type) type = 0;
  if (rate == 0) return -(pv + fv) / nperiod;
  let pvif = Math.pow(1 + rate, nperiod);
  let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);
  if (type == 1) {
    pmt /= 1 + rate;
  }
  return Math.round(pmt);
};

export const preEmiInterest = (
  appliedAmount: number,
  Irr: number,
  preEmiPeriod: number
) => {
  return Math.max(
    Number(
      (
        (appliedAmount * Irr * preEmiPeriod) / 365 
        // (((appliedAmount * Irr * preEmiPeriod) / 365) * Irr * preEmiPeriod) / 365 +
        // (((((appliedAmount * Irr * preEmiPeriod) / 365) * Irr * preEmiPeriod) / 365) * Irr * preEmiPeriod) / 365
      ).toFixed(2)
    ),
    0
  );
};

export const calculatepreEmiPeriod = (preemidate: any, approval_date: any) => {
  let a = moment(preemidate).startOf('day');
  let b = moment(approval_date).startOf('day');
  return a.diff(b, "days");
};

export const DisbursedAmount = (
  finalLoanAmount: number,
  processingFees: number,
  preEmiInterest: number
) => {
  return Number((finalLoanAmount - processingFees).toFixed(2));
};

export const TotalAmountToBeRepaid = (
  finalLoanAmount: number,
  interest: number
) => {
  return Math.round(finalLoanAmount + interest);
};

export const Interest = (
  months: number,
  irr: number,
  amount: number,
  emiAmount: number
) => {
  return Number((emiAmount * months - amount).toFixed(2));
};

export const calculateAverageSalary = (salary: any) => {
  if (salary && salary.length > 0) {
    salary.sort((a: any, b: any) => {
      if (moment(a.payout_date).diff(moment(b.payout_date), "days") < 0) {
        return 1;
      } else {
        return -1;
      }
    });

    salary = salary.slice(0, 3);
    const totalSalary = salary
      .map((item: { amount: number }) => item.amount)
      .reduce((prev: number, next: number) => prev + next);
    return Math.round(totalSalary / salary.length);
  } else {
    return 0;
  }
};

export const calculateAverageSalaryDate = (payouts: any) => {
  // Check if the payouts array is empty
  if (payouts.length === 0) {
    return null; // Return null if the array is empty
  }

  // Calculate the total amount and the number of payouts
  let totalAmount = 0;
  let totalPayouts = payouts.length;

  for (let payout of payouts) {
    totalAmount += payout.amount;
  }

  // Calculate the average amount
  let averageAmount = totalAmount / totalPayouts;

  // Find the date closest to the average amount
  let closestPayout;
  let closestDifference = Infinity;

  for (let payout of payouts) {
    let difference = Math.abs(payout.amount - averageAmount);
    if (difference < closestDifference) {
      closestDifference = difference;
      closestPayout = payout;
    }
  }

  return (closestPayout.payout_date).split("T")[0];
}


export const calculateExpectedSalaryDate = (salary: any, role: any, client_id: any) => {
  if (client_id == 10) {
    return calculateAverageSalaryDate(salary)
  }
  if (role == "monthly") {
    // format the salary as in the top order now
    salary.sort((a: any, b: any) => {
      if (moment(a.payout_date).diff(moment(b.payout_date), "days") < 0) {
        return 1;
      } else {
        return -1;
      }
    });
    salary = salary.slice(0, 3);
    let day = 0;
    let month = -1;

    for (let i: number = salary.length - 1; i >= 0; i--) {
      let date: any;
      let month_temp: number;
      if (salary[i]["payout_date"].includes("T")) {
        date = salary[i]["payout_date"].split("T")[0].split("-")[2];
        month_temp = Number(
          salary[i]["payout_date"].split("T")[0].split("-")[2]
        );
      } else {
        date = salary[i]["payout_date"].split("/")[0];
        month_temp = Number(salary[i]["payout_date"].split("/")[1]);
      }
      if (Number(date) < 17) {
        day += 30 + Number(date);
      } else {
        day += Number(date);
      }
      month = salary[i]["payout_date"].split("T")[0].split("-")[1];
    }

    let currentDate = new Date().toISOString().split("T")[0].split("-");
    let deriveddate: any = Math.ceil(day / salary.length) - 30;

    if (deriveddate <= 0) {
      deriveddate = 30 + deriveddate;
    }
    if (deriveddate < 10) {
      deriveddate = "0" + deriveddate;
    }
    // if date is > = 29 for february reset it to 28

    if (currentDate[1] == "02" && deriveddate >= 29) {
      deriveddate = 28;
    }
    let thismonthdate = `${currentDate[0]}-${currentDate[1]}-${deriveddate}`;
    let nextmonthdate = moment(thismonthdate).add(1, "M").format("YYYY-MM-DD");

    let today = moment(new Date().toISOString());
    let thismonthdateasmoment = moment(thismonthdate);

    //  if(thismonthdateasmoment.diff(today,'days')< 0){
    //      console.log("expected salary date", nextmonthdate)
    //     return nextmonthdate
    //   }
    //   else{
    return thismonthdate;
    // }

    // approval ka month +1
  } else if (role == "weekly") {
    return "";
  }
};

export const calculateEmiFirstDate: any = (
  expected_salary_date: any,
  approval_date: any,
  role: any,
  cmp_id: any
) => {
  if (role == "monthly") {
    if (cmp_id == 2) {
      let expected_day = Number(expected_salary_date.split("-")[2]);
      let required_day = Number(expected_salary_date.split("-")[2]);
      if (expected_day >= 2 && expected_day <= 5) {
        required_day = 5;
      } else if (expected_day >= 6 && expected_day <= 10) {
        required_day = 10;
      } else if (expected_day >= 11 && expected_day <= 20) {
        required_day = 15;
      } else if ((expected_day >= 21 && expected_day <= 31) || expected_day == 1) {
        required_day = 1;
      }
      //hot fix if need
      // let first_emi_date = moment(moment(approval_date).set('date', required_day))
      // first_emi_date = moment(moment(first_emi_date.format('YYYY-MM-DD')))

      let first_emi_date = moment(approval_date).set("date", required_day);

      if (first_emi_date.diff(moment(approval_date), "days") < 0) {
        first_emi_date.add(1, "M");
        // add 1 month to emi first date if the date is before approval date as the date is in past ---- cannot be a possible emi date ---so take next salary date
      }

      // to maintain a emi period of atleasty 20 days
      if (first_emi_date.diff(moment(approval_date), "days") > 20) {
        return first_emi_date.format("YYYY-MM-DD");
      } else {
        return first_emi_date.add(1, "M").format("YYYY-MM-DD");
      }
    }
    else if (cmp_id == 10) {
      let expected_day = Number(expected_salary_date.split("-")[2]);
      let first_emi_date = moment(approval_date).set("date", expected_day).add(1, 'day');

      if (first_emi_date.diff(moment(approval_date), "days") < 0) {
        first_emi_date.add(1, "M");
        // add 1 month to emi first date if the date is before approval date as the date is in past ---- cannot be a possible emi date ---so take next salary date
      }

      // to maintain a emi period of atleasty 20 days
      if (first_emi_date.diff(moment(approval_date), "days") > 20) {
        return first_emi_date.format("YYYY-MM-DD");
      } else {
        return first_emi_date.add(1, "M").format("YYYY-MM-DD");
      }
    }
    else {
      let expected_day = Number(expected_salary_date.split("-")[2]);
      let first_emi_date = moment(approval_date).set("date", expected_day);

      if (first_emi_date.diff(moment(approval_date), "days") < 0) {
        first_emi_date.add(1, "M");
        // add 1 month to emi first date if the date is before approval date as the date is in past ---- cannot be a possible emi date ---so take next salary date
      }

      // to maintain a emi period of atleasty 20 days
      if (first_emi_date.diff(moment(approval_date), "days") > 20) {
        return first_emi_date.format("YYYY-MM-DD");
      } else {
        return first_emi_date.add(1, "M").format("YYYY-MM-DD");
      }
    }
  } else if (role == "weekly") {
    return calculateEmiFirstDateWeekly(approval_date);
  }
};

export const calculatePreEmiDate: any = (
  emi_first_date: any,
  approval_date: any,
  role: any
) => {
  if (role == "monthly") {
    return moment(emi_first_date).subtract(30, "days").format("YYYY-MM-DD");
  } else return calculatePreEmiDateWeekly(emi_first_date);
};

export const calculateTenure = (
  approved_amount: any,
  avg_salary: any,
  role: any,
  clientId = null
) => {
  if (role === "monthly" || role === "weekly") {
    approved_amount = Number(approved_amount);
    avg_salary = Number(avg_salary);

    let maxAllowedTenure;

    if (clientId && LoanConfig[clientId] && LoanConfig[clientId].maxTenure) {
      maxAllowedTenure = LoanConfig[clientId].maxTenure;
    }
    else {
      maxAllowedTenure = Number.MAX_VALUE;
    }

    if (role === "monthly") {
      return Math.min(Math.ceil(approved_amount / (avg_salary * 0.2)), maxAllowedTenure);
    } else if (role === "weekly") {
      return Math.min(calculateTenureWeekly(approved_amount, avg_salary), maxAllowedTenure);
    }
  }
  return 0;
};


export const calculateApprovedAmount = (
  experience: any,
  avg_salary: any,
  amount_requested: any,
  role: any,
  clientId = null
) => {
  let approvedAmount = 0;
  if (role == "monthly") {
    experience = Number(experience);
    avg_salary = Number(avg_salary);
    let rate: number = 0;

    // Determine rate based on experience
    if (experience >= 0 && experience < 3) {
      rate = 0;
    } else if (experience >= 3 && experience < 6) {
      rate = 0.5;
    } else if (experience >= 6 && experience < 12) {
      rate = 0.75;
    } else if (experience >= 12 && experience < 18) {
      rate = 1;
    } else if (experience >= 18 && experience < 24) {
      rate = 1.5;
    } else if (experience >= 24) {
      rate = 2;
    }

    // Calculate the approved amount based on rate and limit to LoanConfig.maxAmount
    approvedAmount = Math.round(avg_salary * rate);
    if (clientId && Boolean(LoanConfig[clientId]) && LoanConfig[clientId]?.maxAmount) {
      approvedAmount = Math.min(approvedAmount, LoanConfig[clientId]?.maxAmount || amount_requested);
    }

  } else if (role == "weekly") {
    let salaryMultiplier = calculateSalaryMultiplierWeekly(experience);
    approvedAmount = calculateApprovedAmountWeekly(
      salaryMultiplier,
      avg_salary,
      amount_requested
    );

    // Limit the approved amount to LoanConfig.maxAmount if clientId is provided
    if (clientId && Boolean(LoanConfig[clientId]) && LoanConfig[clientId]?.maxAmount) {
      approvedAmount = Math.min(approvedAmount, LoanConfig[clientId].maxAmount);
    }
  }

  return approvedAmount;
};


export const calculateIrr = (tenure: any, role: any, clientId = null) => {
  if (clientId && Boolean(LoanConfig[clientId])) {
    console.log("entered in irr")
    return getIRR(clientId, tenure)
  }
  if (role == "monthly") {
    let irr: number = 0.0;
    tenure = Number(tenure);
    switch (tenure) {
      case 1:
        irr = 24.0;
        break;
      case 2:
        irr = 28.0;
        break;
      case 3:
        irr = 30.0;
        break;
      case 4:
        irr = 30.72;
        break;
      case 5:
        irr = 32.0;
        break;
      case 6:
        irr = 32.91;
        break;
      case 7:
        irr = 33.6;
        break;
      case 8:
        irr = 34.13;
        break;
      case 9:
        irr = 34.56;
        break;
      case 10:
        irr = 34.91;
        break;
      case 11:
        irr = 35.2;
        break;
      case 12:
        irr = 36.0;
        break;
    }
    return irr.toFixed(2);
  } else if (role == "weekly") {
    return calculateIrrWeekly(tenure);
  }
};

function getIRR(clientId: string, tenure: number) {
  // Check if the clientId exists in LoanConfig
  if (LoanConfig[clientId] && LoanConfig[clientId].irrMatrix) {
    const irrMatrix = LoanConfig[clientId].irrMatrix;
    const matchingIRR = irrMatrix.find((item: { tenure: number; }) => item.tenure === tenure);
    if (matchingIRR) {
      return matchingIRR.irr;
    }
  }
}


export const calculateProcesasingFee = (role: any, approved_amount: any, clientId = null) => {
  if (clientId && Boolean(LoanConfig[clientId]) && LoanConfig[clientId].procFee) {
    return getProcessingFee(approved_amount, clientId)
  }
  if (role == "monthly") {
    if (Number(approved_amount) >= 10000) {
      return 708;
    }
    return 649;
  } else if (role == "weekly") {
    return calculateProcesasingFeeWeekly(approved_amount);
  }
};

export const getProcessingFee = (approvedAmount: number, clientId = null) => {
  console.log("approved range", approvedAmount)
  // Base additional fee calculation on the approved amount
  const additionalFee = approvedAmount >= 10000 ? 118 : 59;

  // Initialize a variable to hold the calculated processing fee
  let processingFee = 0;

  if (clientId && LoanConfig[clientId] && LoanConfig[clientId].procFee) {
    if (LoanConfig[clientId].procFee.flat) {
      const { amount, percent, operator } = LoanConfig[clientId].procFee.flat;
      const calculatedFeeByPercent = (approvedAmount * percent) / 100;

      // Determine processing fee based on operator "max" or "min"
      if (operator === "max") {
        processingFee = Math.max(amount, calculatedFeeByPercent);
      } else if (operator === "min") {
        processingFee = Math.min(amount, calculatedFeeByPercent);
      }

      // Apply tax of 18% to the processing fee and round to the nearest integer
      processingFee = parseInt((processingFee * 1.18).toFixed(2));
      console.log("fee range", processingFee)

    } else if (LoanConfig[clientId].procFee.range) {
      const feeRange = LoanConfig[clientId].procFee.range.find((range: { min: number; max: number; pf: number }) => {
        return (
          approvedAmount >= range.min && approvedAmount <= range.max
        );
      });
      if (feeRange) {
        console.log("fee range", feeRange)
        processingFee = feeRange.pf;
      }
    }
  }

  // Add the additional fee to the final processing fee (after tax calculation for flat fees, if applicable)
  return processingFee + additionalFee;
};

export const calculateHealthPremium = (loan_amount: number, tenure: number) => {
  if (loan_amount <= 20000) {
    if (tenure <= 6) {
      return 354;
    } else {
      return 708;
    }
  } else if (loan_amount > 20000) {
    if (tenure <= 6) {
      return 590;
    } else {
      return 1180;
    }
  } else {
    return 0
  }
};

export const calculateSalaryMultiplier = (
  approved_amount: any,
  average_salary: any,
  role: any,
  experience: any,
  clientId = null
) => {
  let salary_multiplier: number = 0;

  if (clientId && Boolean(LoanConfig[clientId])) {
    return getMultiplier(clientId, experience)
  }
  if (role == "weekly") {
    salary_multiplier = calculateSalaryMultiplierWeekly(experience);
  } else if (role == "monthly") {
    salary_multiplier = Number(
      (Number(approved_amount) / Number(average_salary)).toFixed(1)
    );
  }
  return salary_multiplier;
};
export const getMultiplier = (clientId: any, experience: any) => {
  console.log(clientId)
  console.log(experience)
  const clientConfig = LoanConfig[clientId];

  if (!clientConfig || !clientConfig["salaryMatrix"]) {
    return null;
  }

  const metricConfig = clientConfig["salaryMatrix"];
  console.log(metricConfig)
  if (!Array.isArray(metricConfig)) {
    return null; // Metric is not an array (e.g., salaryMatrix)
  }

  // Find the multiplier range that matches the experience
  const matchingRange = metricConfig.find((range) => {
    return ((experience >= range.min) && (experience <= range.max)
    );
  });
  console.log(matchingRange)
  if (!matchingRange) {
    return null; // No matching range found
  }
  console.log(matchingRange.multiplier)
  return matchingRange.multiplier;
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}