export const LoanConfig: any = {
	"1": {
		"salaryCycle": "weekly",
		"maxAmount": 100000,
		"maxTenure": 52,
		"salaryMatrix": [],
		"irrMatrix": [],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"2": {
		"salaryCycle": "monthly",
		"maxAmount": 100000,
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 12,
				"multiplier": 0.75
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32.00
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"range": [
				{
					"min": 0,
					"max": 10000,
					"pf": 590
				},
				{
					"min": 10001,
					"max": 25000,
					"pf": 767
				},
				{
					"min": 25001,
					"max": 35000,
					"pf": 944
				},
				{
					"min": 35001,
					"max": 50000,
					"pf": 1180
				},
				{
					"min": 50001,
					"max": Number.MAX_VALUE,
					"pf": 1416
				}
			]
		}
	},
	"3": {},
	"4": {},
	"5": {},
	"6": {},
	"7": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32.00
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"8": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32.00
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"9": {
		"salaryCycle": "monthly",
		"maxAmount": 100000,
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 12,
				"multiplier": 0.75
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32.00
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"range": [
				{
					"min": 0,
					"max": 10000,
					"pf": 590
				},
				{
					"min": 10001,
					"max": 25000,
					"pf": 767
				},
				{
					"min": 25001,
					"max": 35000,
					"pf": 944
				},
				{
					"min": 35001,
					"max": 50000,
					"pf": 1180
				},
				{
					"min": 50001,
					"max": Number.MAX_VALUE,
					"pf": 1416
				}
			]
		}
	},
	"10": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 15
			},
			{
				"tenure": 2,
				"irr": 20
			},
			{
				"tenure": 3,
				"irr": 22.50
			},
			{
				"tenure": 4,
				"irr": 24
			},
			{
				"tenure": 5,
				"irr": 25
			},
			{
				"tenure": 6,
				"irr": 25.71
			},
			{
				"tenure": 7,
				"irr": 26.25
			},
			{
				"tenure": 8,
				"irr": 26.67
			},
			{
				"tenure": 9,
				"irr": 27
			},
			{
				"tenure": 10,
				"irr": 27.27
			},
			{
				"tenure": 11,
				"irr": 27.50
			},
			{
				"tenure": 12,
				"irr": 27.69
			}
		],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"11": {},
	"12": {},
	"13": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 18
			},
			{
				"tenure": 2,
				"irr": 24
			},
			{
				"tenure": 3,
				"irr": 27
			},
			{
				"tenure": 4,
				"irr": 28.80
			},
			{
				"tenure": 5,
				"irr": 30
			},
			{
				"tenure": 6,
				"irr": 30.86
			},
			{
				"tenure": 7,
				"irr": 31.50
			},
			{
				"tenure": 8,
				"irr": 32
			},
			{
				"tenure": 9,
				"irr": 32.40
			},
			{
				"tenure": 10,
				"irr": 32.73
			},
			{
				"tenure": 11,
				"irr": 33
			},
			{
				"tenure": 12,
				"irr": 33.23
			}
		],
		"procFee": {
			"flat": {
				"amount": 300,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"14": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"15": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"16": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"17": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 18
			},
			{
				"tenure": 2,
				"irr": 24
			},
			{
				"tenure": 3,
				"irr": 27
			},
			{
				"tenure": 4,
				"irr": 28.80
			},
			{
				"tenure": 5,
				"irr": 30
			},
			{
				"tenure": 6,
				"irr": 30.86
			},
			{
				"tenure": 7,
				"irr": 31.50
			},
			{
				"tenure": 8,
				"irr": 32
			},
			{
				"tenure": 9,
				"irr": 32.40
			},
			{
				"tenure": 10,
				"irr": 32.73
			},
			{
				"tenure": 11,
				"irr": 33
			},
			{
				"tenure": 12,
				"irr": 33.23
			}
		],
		"procFee": {
			"flat": {
				"amount": 300,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"18": {
		"product": "monthly",
		"salaryCycle": "monthly",
		"maxAmount": 50000,
		"maxTenure": 6,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 18
			},
			{
				"tenure": 2,
				"irr": 24
			},
			{
				"tenure": 3,
				"irr": 27
			},
			{
				"tenure": 4,
				"irr": 28
			},
			{
				"tenure": 5,
				"irr": 30
			},
			{
				"tenure": 6,
				"irr": 30
			},
			{
				"tenure": 7,
				"irr": 31
			},
			{
				"tenure": 8,
				"irr": 32
			},
			{
				"tenure": 9,
				"irr": 32
			},
			{
				"tenure": 10,
				"irr": 32
			},
			{
				"tenure": 11,
				"irr": 33
			},
			{
				"tenure": 12,
				"irr": 33
			}
		],
		"procFee": {
			"flat": {
				"amount": 300,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"19": {
		"product": "monthly",
		"salaryCycle": "monthly",
		"maxAmount": 25000,
		"maxTenure": 6,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 18
			},
			{
				"tenure": 2,
				"irr": 24
			},
			{
				"tenure": 3,
				"irr": 27
			},
			{
				"tenure": 4,
				"irr": 28.80
			},
			{
				"tenure": 5,
				"irr": 30
			},
			{
				"tenure": 6,
				"irr": 30.86
			},
			{
				"tenure": 7,
				"irr": 31.50
			},
			{
				"tenure": 8,
				"irr": 32
			},
			{
				"tenure": 9,
				"irr": 32.40
			},
			{
				"tenure": 10,
				"irr": 32.73
			},
			{
				"tenure": 11,
				"irr": 33
			},
			{
				"tenure": 12,
				"irr": 33.23
			}
		],
		"procFee": {
			"range": [
				{
					"min": 0,
					"max": 10000,
					"pf": 295
				},
				{
					"min": 10001,
					"max": 15000,
					"pf": 413
				},
				{
					"min": 15001,
					"max": 20000,
					"pf": 531
				},
				{
					"min": 20001,
					"max": Number.MAX_VALUE,
					"pf": 649
				}
			]
		}
	},
	"20": {
		"salaryCycle": "monthly",
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 18
			},
			{
				"tenure": 2,
				"irr": 24
			},
			{
				"tenure": 3,
				"irr": 27
			},
			{
				"tenure": 4,
				"irr": 28.80
			},
			{
				"tenure": 5,
				"irr": 30
			},
			{
				"tenure": 6,
				"irr": 30.86
			},
			{
				"tenure": 7,
				"irr": 31.50
			},
			{
				"tenure": 8,
				"irr": 32
			},
			{
				"tenure": 9,
				"irr": 32.40
			},
			{
				"tenure": 10,
				"irr": 32.73
			},
			{
				"tenure": 11,
				"irr": 33
			},
			{
				"tenure": 12,
				"irr": 33.23
			}
		],
		"procFee": {
			"flat": {
				"amount": 200,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"21": {
		"salaryCycle": "monthly",
		"maxAmount": 50000,
		"maxTenure": 6,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 18
			},
			{
				"tenure": 2,
				"irr": 24
			},
			{
				"tenure": 3,
				"irr": 27
			},
			{
				"tenure": 4,
				"irr": 28.80
			},
			{
				"tenure": 5,
				"irr": 30
			},
			{
				"tenure": 6,
				"irr": 30.86
			},
			{
				"tenure": 7,
				"irr": 31.50
			},
			{
				"tenure": 8,
				"irr": 32
			},
			{
				"tenure": 9,
				"irr": 32.40
			},
			{
				"tenure": 10,
				"irr": 32.73
			},
			{
				"tenure": 11,
				"irr": 33
			},
			{
				"tenure": 12,
				"irr": 33.23
			}
		],
		"procFee": {
			"flat": {
				"amount": 300,
				"percent": 2,
				"operator": "max"
			}
		}
	},
	"22": {
		"salaryCycle": "monthly",
		"maxAmount": 100000,
		"maxTenure": 12,
		"salaryMatrix": [
			{
				"min": 0,
				"max": 3,
				"multiplier": 0
			},
			{
				"min": 3,
				"max": 6,
				"multiplier": 0.5
			},
			{
				"min": 6,
				"max": 9,
				"multiplier": 0.75
			},
			{
				"min": 9,
				"max": 12,
				"multiplier": 0.85
			},
			{
				"min": 12,
				"max": Number.MAX_VALUE,
				"multiplier": 1
			}
		],
		"irrMatrix": [
			{
				"tenure": 1,
				"irr": 19.20
			},
			{
				"tenure": 2,
				"irr": 25.60
			},
			{
				"tenure": 3,
				"irr": 28.80
			},
			{
				"tenure": 4,
				"irr": 30.72
			},
			{
				"tenure": 5,
				"irr": 32
			},
			{
				"tenure": 6,
				"irr": 32.91
			},
			{
				"tenure": 7,
				"irr": 33.60
			},
			{
				"tenure": 8,
				"irr": 34.13
			},
			{
				"tenure": 9,
				"irr": 34.56
			},
			{
				"tenure": 10,
				"irr": 34.91
			},
			{
				"tenure": 11,
				"irr": 35.20
			},
			{
				"tenure": 12,
				"irr": 35.45
			}
		],
		"procFee": {
			"flat": {
				"amount": 300,
				"percent": 4,
				"operator": "max"
			}
		}
	}
};

export const capConfig: any = {
	"2": { "MaxInterestRate": 26, "MaxProcessingFees": 5 },
	"3": { "MaxInterestRate": 28, "MaxProcessingFees": 6 },
	"4": { "MaxInterestRate": 31, "MaxProcessingFees": 7 },
	"5": { "MaxInterestRate": 32, "MaxProcessingFees": 8 },
	"6": { "MaxInterestRate": 33, "MaxProcessingFees": 9 },
	"7": { "MaxInterestRate": 34, "MaxProcessingFees": 10 },
	"8": { "MaxInterestRate": 36, "MaxProcessingFees": 10 },
	"9": { "MaxInterestRate": 38, "MaxProcessingFees": 10 },
	"10": { "MaxInterestRate": 40, "MaxProcessingFees": 10 },
	"11": { "MaxInterestRate": 40, "MaxProcessingFees": 10 },
	"12": { "MaxInterestRate": 40, "MaxProcessingFees": 10 }
  }